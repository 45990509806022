import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js'

// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
// import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass.js'


import gsap from 'gsap'
import * as CANNON from 'cannon-es'

// import * as dat from 'lil-gui'




/**
 * Base
 */

let bricksExploded = false
let onBricks = false

// Is mobile
let isMobile


if(window.innerWidth < 768) {
    isMobile = true
} else {
    isMobile = false
}




// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.wireframe = true

// Axes helper
// const axesHelper = new THREE.AxesHelper(5)
// scene.add(axesHelper)

// Physics
const world = new CANNON.World()
world.broadphase = new CANNON.SAPBroadphase(world)
world.allowSleep = true
world.gravity.set(0, -9.82, 0)

// Physics materials
const defaultMaterial = new CANNON.Material('default')

const defaultPlasticContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
        friction: 0.01,
        restitution: 0.7
    }
)

world.addContactMaterial(defaultPlasticContactMaterial)

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const particleTexture = textureLoader.load('/textures/particles/1.png')
const clairvoyant_icone_1 = textureLoader.load('/images/clairvoyant.svg')
const clairvoyant_icone_2 = textureLoader.load('/images/identite-visuelle.svg')
const clairvoyant_icone_3 = textureLoader.load('/images/lyonnais.svg')
const clairvoyant_icone_4 = textureLoader.load('/images/remarquables.svg')
const clairvoyant_icone_5 = textureLoader.load('/images/site-internet.svg')


// Icone size
clairvoyant_icone_1.minFilter = THREE.NearestFilter
clairvoyant_icone_1.magFilter = THREE.NearestFilter
clairvoyant_icone_1.generateMipmaps = false

clairvoyant_icone_2.minFilter = THREE.NearestFilter
clairvoyant_icone_2.magFilter = THREE.NearestFilter
clairvoyant_icone_2.generateMipmaps = false

clairvoyant_icone_3.minFilter = THREE.NearestFilter
clairvoyant_icone_3.magFilter = THREE.NearestFilter
clairvoyant_icone_3.generateMipmaps = false

clairvoyant_icone_4.minFilter = THREE.NearestFilter
clairvoyant_icone_4.magFilter = THREE.NearestFilter
clairvoyant_icone_4.generateMipmaps = false

clairvoyant_icone_5.minFilter = THREE.NearestFilter
clairvoyant_icone_5.magFilter = THREE.NearestFilter
clairvoyant_icone_5.generateMipmaps = false


/**
 * Lights
 */

// Ghosts lights
const ghost1 = new THREE.PointLight('#3de3ec', 2, 10)
scene.add(ghost1)

// move ghost1
ghost1.position.set(0, -0.5, 0)



const ghost2 = new THREE.PointLight('#fff', 1, 7)
scene.add(ghost2)


/** 
 * Meshes
 */

// FLOOR

// Floor physics
const floorShape = new CANNON.Plane()
const floorBody = new CANNON.Body({
    mass: 0,
    shape: floorShape,
    material: defaultMaterial
})
floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(-1, 0, 0), Math.PI * 0.5)

// set floor position
floorBody.position.set(0, -2, 0)

world.addBody(floorBody)


// Floor mesh
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(100, 100),
    new THREE.MeshStandardMaterial({ 
        color: '#5d5ffa',
        transparent: true,
        opacity: 0.5
     })
)
floor.rotation.x = - Math.PI * 0.5
floor.position.y = -2
floor.receiveShadow = true

scene.add(floor)


// // Add wall plane
const wallShape = new CANNON.Plane()
const wallBody = new CANNON.Body({
    mass: 0,
    shape: wallShape,
    material: defaultMaterial
})

// set wall position
wallBody.position.set(-4, 0, 0)

// rotate wall
wallBody.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), Math.PI * 0.1)

world.addBody(wallBody)


// HAMMER

// Hammer group
const hammerGroup = new THREE.Group()
scene.add(hammerGroup)

if(isMobile) {
    hammerGroup.position.set(1, 0, -0.8)
    hammerGroup.rotation.set(.3, 0, 0)
}

// Open gltf model
const loader = new GLTFLoader();

// hammer model
var hammer = null;
loader.load(
    '/models/hammer/scene.gltf',
    (gltf) =>
    {

        hammer = gltf.scene.children[0]
        hammer.scale.set(0.05, 0.05, 0.05)
        hammer.position.set(-2.8, 0, 1)
        hammer.rotation.set(-1.5, 0, 1)

        hammerGroup.add(hammer)
    }
)

// add light to hammer
const hammerLight = new THREE.PointLight('#ff00ff', 1, 1)
hammerLight.position.set(-3.2, 0.5, 1)
hammerGroup.add(hammerLight)


// NUMBERS

// Create group
const numbersGroup_1 = new THREE.Group()
const numbersGroup_2 = new THREE.Group()
const numbersGroup_3 = new THREE.Group()
const numbersGroup_4 = new THREE.Group()

const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/faune.typeface.json',
    (font) =>
    {
        // 2
        const twoGeometry = new TextGeometry(
            '2',
            {
                font: font,
                size: 1.6,
                height: 0.3,
                curveSegments: 8,
                bevelEnabled: true,
                bevelThickness: 0.05,
                bevelSize: 0.05,
                bevelOffset: 0,
                bevelSegments: 1,
            }
        )
        twoGeometry.center()
            
        const twoMaterial =  new THREE.MeshNormalMaterial(
            {
                // side: THREE.FrontSide
                // wireframe: true
            }
        )

        const two = new THREE.Mesh(
            twoGeometry,
            twoMaterial
        )

        two.position.set(-1, 0.8, 0)
        // two.rotation.set(0, 0.3, 0)

        // two.castShadow = true

        numbersGroup_2.add(two)

        // clone two
        const twoClone = two.clone()
        twoClone.position.set(-1, -1, 0)
        // update material
        twoClone.material = new THREE.MeshNormalMaterial(
            {
                // wireframe: true
            }
        )

        // twoClone.castShadow = false
        
        // twoClone.rotation.set(0, 0.3, 0)
        numbersGroup_4.add(twoClone)


        // 0
        const zeroGeometry = new TextGeometry(
            '0',
            {
                font: font,
                size: 1.6,
                height: 0.3,
                curveSegments: 8,
                bevelEnabled: true,
                bevelThickness: 0.05,
                bevelSize: 0.05,
                bevelOffset: 0,
                bevelSegments: 1,
            }
        )
        zeroGeometry.center()
        
        const zeroMaterial =new THREE.MeshNormalMaterial(
            {
                // side: THREE.FrontSide,
                // wireframe: true
            }
        )

        const zero = new THREE.Mesh(zeroGeometry, zeroMaterial)
        zero.position.set(0.4, 0.8, 0)
        // zero.rotation.set(0, 0.3, 0)

        // zero.castShadow = true

        numbersGroup_1.add(zero)

        // 3
        const threeGeometry = new TextGeometry(
            '3',
            {
                font: font,
                size: 1.6,
                height: 0.3,
                curveSegments: 8,
                bevelEnabled: true,
                bevelThickness: 0.05,
                bevelSize: 0.05,
                bevelOffset: 0,
                bevelSegments: 1,
            }
        )
        threeGeometry.center()
        
        const threeMaterial = new THREE.MeshNormalMaterial(
            {
                // side: THREE.BackSide
                // wireframe: true
            }
        )

        const three = new THREE.Mesh(threeGeometry, threeMaterial)


        three.position.set(0.4, -1, 0)
        // three.rotation.set(0, 0.3, 0)

        // cast shadow
        // three.castShadow = true

        numbersGroup_3.add(three)
    }
)


// Add all numbers to new group
const numbersGroup = new THREE.Group()
numbersGroup.add(numbersGroup_1, numbersGroup_2, numbersGroup_3, numbersGroup_4)
// position
numbersGroup.position.set(-2.3, 0, -0.5)
if(isMobile) {
    numbersGroup.position.set(-2, 0, -1.5)
}

scene.add(numbersGroup)



// BRICKS

// bricks group
const bricksGroup = new THREE.Group()
scene.add(bricksGroup)

// set position
bricksGroup.rotation.set(0, 0.3, 0)

if(isMobile) {
    bricksGroup.position.set(-2, 0, -3)
} else {
    bricksGroup.position.set(-2.5, 0, -2)
}

// Create a wall of 40 bricks
const bricks = []
for(let i = 0; i < 24; i++)
{
    // add brick
    const brick = new THREE.Mesh(
        new RoundedBoxGeometry( 1, 1, 1, 1, 0.04 ),
        new THREE.MeshPhysicalMaterial(
            {
                color: 0xffffff,
                metalness: 0.5,
                roughness: 0.5,
                clearcoat: 0.5,
                clearcoatRoughness: 1,
                reflectivity: 0.5,
                transmission: 1,
                transparent: true,
                opacity: 1,
                emissive: 0xffffff,
                emissiveIntensity: 0.8,
            }
        )
    )

    // add plane to brick
    if(i === 7  || i === 4 || i === 11 || i === 17 || i === 23) {
        let icon;
        if(i === 7) {
            icon = clairvoyant_icone_1
            } else if(i === 4) {
            icon = clairvoyant_icone_2
            } else if(i === 11) {
            icon = clairvoyant_icone_3
            } else if(i === 17) {
            icon = clairvoyant_icone_4
            } else if(i === 23) {
            icon = clairvoyant_icone_5
        }
        
        const plane = new THREE.Mesh(
            new THREE.PlaneGeometry(1, 1, 1, 1),
            // add texture image to plane
            new THREE.MeshBasicMaterial(
                {
                    map: icon,
                    side: THREE.DoubleSide,
                    transparent: true
                }

            )
        )
        plane.position.set(0, 0, 0.51)
        plane.rotation.set(0, 0, Math.PI / 2 * 4)
        plane.scale.set(0.5, 0.5, 0.5)
        brick.add(plane)

        // clone plane for other side
        const planeClone = plane.clone()
        planeClone.position.set(0, 0, -0.51)
        planeClone.rotation.set(0, 0, Math.PI / 2 * 2)
        brick.add(planeClone)

    }



    brick.position.x = (i % 4.5) - 2.5
    brick.position.y = Math.floor(i / 5) - 1.5
    brick.position.z = 2
    brick.castShadow = true
    bricksGroup.add(brick)
    bricks.push(brick)

    // add brick body
    const brickBody = new CANNON.Body(
        {
            mass: 1,
            position: new CANNON.Vec3(0, 0, 0),
            shape: new CANNON.Box(new CANNON.Vec3(0.5, 0.5, 0.5)),
            material: defaultMaterial,
        }
    )
    world.addBody(brickBody)
}

// onclick on sound button play sound
// const soundButton = document.querySelector('.sound-button')
// const sound = new Audio('/sounds/drums.mp3')
// sound.play()

// soundButton.addEventListener('click', () =>
// {
//     sound.loop = true
    
// })

        // Add particles on background of the scene
        const particleGeometry = new THREE.BufferGeometry()
        const particleCount = 1000
        const posArray = new Float32Array(particleCount * 3)

        for(let i = 0; i < particleCount * 3; i++)
        {
            posArray[i] = (Math.random() - 0.5) * 10
        }

        particleGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3))

        const particleMaterial = new THREE.PointsMaterial({
            size: 0.01,
            sizeAttenuation: true,
            // random color
            color: '#FFF',
            map: particleTexture,
            transparent: true,
            alphaMap: particleTexture,
            // alphaTest: 0.001,
            // depthWrite: true,
            depthTest: true,
            // blending: THREE.AdditiveBlending,      
            // vertexColors: true   
            opacity: 0.4,
        })



        const particles = new THREE.Points(particleGeometry, particleMaterial)

        // particles.position.set(-3,2,-3)

        scene.add(particles)




// explode bricks
const explodeBricks = (bricks) =>
{

    // const music = new Audio('/sounds/2023.mp3')
    // music.play()

    bricks.forEach(brick => {

        const explosionCenter = new CANNON.Vec3(0, 0, 0); // center of the explosion
        let explosionForce = Math.random() * 1000 + 1000;

        // log index 
        if(bricks.indexOf(brick) == 4 || bricks.indexOf(brick) == 7 || bricks.indexOf(brick) == 11 || bricks.indexOf(brick) == 17 || bricks.indexOf(brick) == 23) {
            explosionForce = 0;
        }

        const x = brick.position.x
        const y = brick.position.y
        const z = brick.position.z

        // get brick dimensions
        const brickDimensions = brick.geometry.parameters

        // create a new body for the brick
        brick.body = new CANNON.Body({
            mass: 1,
            position: new CANNON.Vec3( x, y, z ),
            shape: new CANNON.Box(new CANNON.Vec3(1 * 0.5, 1 * 0.5, 1 * 0.5)),
            material: defaultMaterial
        })

        // apply rotation force to brick
        brick.body.angularVelocity.set(
            Math.random() * 10 - 5,
            Math.random() * 10 - 5,
            Math.random() * 10 - 5
        );

        // calculate distance of brick from explosion center
        const distance = brick.position.distanceTo(explosionCenter);

        // calculate force to apply to brick based on distance
        const force = explosionForce / (distance + 1); // add 1 to avoid division by zero

        // apply force to brick in direction of explosion center
        brick.body.applyForce(
            new CANNON.Vec3(
                (x - explosionCenter.x) * force,
                (y - explosionCenter.y) * force,
                (z - explosionCenter.z) * force
            ),
            new CANNON.Vec3(x, y, z)

        );

        world.addBody(brick.body)


        if(bricks.indexOf(brick) == 4 || bricks.indexOf(brick) == 7 || bricks.indexOf(brick) == 11 || bricks.indexOf(brick) == 17 || bricks.indexOf(brick) == 23) {

            if(bricks.indexOf(brick) == 23) {
            brick.position.x = 3
            brick.body.position.x = 3

            brick.position.y = 3
            brick.body.position.y = 3

            brick.position.z = 0
            brick.body.position.z = 0
            }            
            
        } else {


            // change opacity material
            gsap.to(brick.material, { duration: 3, opacity: 0, delay: 3 })

            // remove shadow
            setTimeout(() => {
                brick.material.transmission = 0
                brick.castShadow = false
            }, 3000)


            setTimeout(() => {
                world.removeBody(brick.body)
                bricksGroup.remove(brick)
            }, 6000)
        }

        if(isMobile) {
            // animate numbers position
            gsap.to(numbersGroup.position, { duration: 3, x: 0.4, y: 2.3, z: 0, delay: 0.2, ease: 'power4.out' })

            // rotate numbers
            gsap.to(numbersGroup.rotation, { duration: 3, x: 0, y: 0.7, z: 0, delay: 0.2, ease: 'power4.out' })

            // scale
            gsap.to(numbersGroup.scale, { duration: 3, x: 0.6, y: 0.6, z: 0.6, delay: 0.2, ease: 'power4.out' })

            // move camera
            // gsap.to(camera.position, { duration: 3, x: 2, y: 0, z: 10 })

            // mov

            // Change camera parameters
            // camera.fov = 120
            // camera.updateProjectionMatrix()


            
        } else {
            gsap.to(numbersGroup.position, { duration: 10, x: -2.3, y: 0.7, z: -0.5, ease: 'power4.out' })
        }
        

        // update material color
        // random color
        // brick.material.color.set(Math.random() * 0xffffff)
        // brick.material.emissive.r = brick.material.color.r
        // brick.material.emissive.g = brick.material.color.g
        // brick.material.emissive.b = brick.material.color.b
        // brick.material.color.set(0xff0000)


        // update ambient light
        // gsap.to(ambientLight, { duration: 1, intensity: 0.3 })

        // renderer.setClearColor('#D20C0C', 1)
        // fog.color.set('#D20C0C')
    })
}


// explode bricks on clicks
document.addEventListener('mousedown', () =>
{
    // explode bricks -- prevent multiple clicks
    if(!bricksExploded && onBricks)
    {
        // rotate hammer, check if on brick and reset rotation
        gsap.to(hammer.rotation, {x: -Math.PI / 1.5, y: -0.5, duration: 0.1})
            
        // after 0.1s explode bricks
        setTimeout(() => {
            
        explodeBricks(bricks)
        scene.remove(hammerGroup)

        // update .message html
        document.querySelector('.message').innerHTML = 'Nous vous souhaitons une <span class="border">excellente année</span> !<br><br>Micka & Fanny<br><small><small><a href="https://www.instagram.com/studio_clairvoyant/" target="_blank">@studio_clairvoyant</a><small><small>'

        // remove hide class on .btn
        document.querySelector('.btn').classList.remove('hide')

        document.querySelector('.card-text').classList.add('rotate')


        }, 200)


        gsap.to(hammer.rotation, {x: -1.5, y: 0, duration: 0.2, delay: 0.1})

        bricksExploded = true

        // set cursor to default after delay
        setTimeout(() => {

            document.body.style.cursor = 'default'
        
        }, 200)
    }

    // add class active on card-wrapper
    document.querySelector('.card-wrapper').classList.add('active')
});


// Mouse move or touche move
document.addEventListener('mousemove', onMouseMove)


let mouseX = 0
let mouseY = 0

let cameraX = 0
// let cameraY = 0

let targetX = 0
let targetY = 0

const windowHalfX = window.innerWidth / 2
const windowHalfY = window.innerHeight / 2


function onMouseMove(event) {


    // Récupère les coordonnées de la souris
    mouseX = (event.clientX - windowHalfX) * 0.01 + 2.3
    mouseY = -(event.clientY - windowHalfY) * 0.01 

    cameraX = (event.clientX - windowHalfX)


    // Met à jour la position de l'objet
    if(mouseX > -1.6 && mouseX < 3.5 && mouseY > -2.3) {
        if(bricksExploded == false) {
            hammerGroup.position.set(mouseX, mouseY, -mouseX * 0.3);
            document.body.style.cursor = 'none'
            onBricks = true
        }
    } else {
        gsap.to(hammerGroup.position, {x: 1, y: 0, z: 0, duration: 0.05, delay: 0.1})
        // show cursor
        document.body.style.cursor = 'default'
        onBricks = false
    }

}


// Fog
const fog = new THREE.Fog('#121230', 4, 12)
scene.fog = fog


/**
 * Lights
 */
// Ambient light
const ambientLight = new THREE.AmbientLight('#FFF',0.5)
// gui.add(ambientLight, 'intensity').min(0).max(1).step(0.001)
scene.add(ambientLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // check if width is less than 700px
    if(sizes.width < 768) {
        isMobile = true
        camera.position.x = 5
        camera.position.y = 1
        camera.position.z = 3
    } else {
        isMobile = false
        camera.position.x = 5
        camera.position.y = 2
        camera.position.z = 5
    }

    if(!isMobile) {
        // reset numbers position with gsap
        if(bricksExploded) {
            gsap.to(numbersGroup.position, { duration: 1, x: -2.3, y: 0.7, z: -0.5 })
        } else {
            gsap.to(numbersGroup.position, { duration: 1, x: -2.3, y: 0, z: -0.5 })
        }

        // reset scale
        gsap.to(numbersGroup.scale, { duration: 1, x: 1, y: 1, z: 1 })

        // reset rotation
        gsap.to(numbersGroup.rotation, { duration: 1, x: 0, y: 0, z: 0 })


        // hammerGroup.position.set(0, 0, 0)
        // hammerGroup.rotation.set(0, 0, 0)

        // numbersGroup.position.set(-2.3, 0, -0.5)

        gsap.to(bricksGroup.position, { duration: 1, x: -2.5, y: 0, z: -2 })


    } else {
        if(bricksExploded) {
            gsap.to(numbersGroup.position, { duration: 1, x: 0.4, y: 2.3, z: 0 })
        } else {
            gsap.to(bricksGroup.position, { duration: 1, x: -2, y: 0, z: -3 })
            gsap.to(numbersGroup.position, { duration: 1, x: -2, y: 0, z: -1.5 })
        }
    }
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 5
camera.position.y = 2
camera.position.z = 5

// if mobile 
if(isMobile) {
    camera.position.x = 5
    camera.position.y = 1
    camera.position.z = 3
}


scene.add(camera)


// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = false

// prevent camera from going under the ground
if(!isMobile) {
controls.minDistance = 6
controls.maxDistance = 6
controls.maxPolarAngle = Math.PI * 0.49
}


// prevent to move the camera
controls.enablePan = false

controls.enableRotate = false

controls.enableKeys = false

controls.enableDamping = false

controls.enableZoom = false

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor('#121230')


// renderer.gammaFactor = 2.2
// renderer.gammaOutput = true

// renderer.physicallyCorrectLights = true

// renderer.toneMapping = THREE.ACESFilmicToneMapping
// renderer.toneMappingExposure = 1

// renderer.shadowMap.enabled = true
// renderer.shadowMap.type = THREE.PCFSoftShadowMap

// renderer.outputEncoding = THREE.sRGBEncoding

// renderer.setClearColor(0x000000, 0)



// Add noise effect on renderer
// const renderScene = new RenderPass(scene, camera)

// const effectFilm = new FilmPass(
//     0.3,   // noise intensity
//     0,  // scanline intensity
//     0,    // scanline count
//     false   // grayscale
// )
// effectFilm.renderToScreen = true

// const composer = new EffectComposer(renderer)
// composer.addPass(renderScene)
// composer.addPass(effectFilm)







/**
 * Shadows
 */
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap

ghost1.castShadow = true
ghost2.castShadow = true

ghost1.shadow.mapSize.width = 256
ghost1.shadow.mapSize.height = 256
ghost1.shadow.camera.far = 7

ghost2.shadow.mapSize.width = 256
ghost2.shadow.mapSize.height = 256
ghost2.shadow.camera.far = 7

/**
 * Animate
 */
const clock = new THREE.Clock()

let currentIntersect = null

let oldElapsedTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - oldElapsedTime
    oldElapsedTime = elapsedTime

    // Update physics world
    world.step(1 / 60, deltaTime, 3)


    // Update bricks
    bricks.forEach((brick, index) => {

        if(brick.body) {
            brick.position.x = brick.body.position.x
            brick.position.y = brick.body.position.y
            brick.position.z = brick.body.position.z
            brick.quaternion.x = brick.body.quaternion.x
            brick.quaternion.y = brick.body.quaternion.y
            brick.quaternion.z = brick.body.quaternion.z
            brick.quaternion.w = brick.body.quaternion.w
            
        }
    })

    // Ghosts
    // const ghost1Angle = elapsedTime * 0.2
    // ghost1.position.x = Math.cos(ghost1Angle) * 1 - 3
    // ghost1.position.z = Math.sin(ghost1Angle) * 1
    // ghost1.position.y = Math.sin(elapsedTime * 2)

    const ghost2Angle = - elapsedTime * 0.32
    ghost2.position.x = Math.cos(ghost2Angle) * 2 - 3
    ghost2.position.z = Math.sin(ghost2Angle) * 2
    ghost2.position.y = Math.sin(elapsedTime * 3) + Math.sin(elapsedTime * 2.5)    

    
    // number ballon 1/2 rotation and return to the original position
    numbersGroup_1.rotation.y = Math.sin(elapsedTime * 0.5) / 3 + 0.3
    numbersGroup_1.position.y = Math.sin(elapsedTime * 0.5) / 6 + 0.3

    // inverse rotation for the number ballon 2
    numbersGroup_2.rotation.y = -Math.sin(elapsedTime * 0.5) / 3 + 0.3
    numbersGroup_2.position.y = Math.sin(elapsedTime * 0.5) / 6 + 0.3

    // ballon 3
    numbersGroup_3.rotation.y = Math.sin(elapsedTime * 0.5) / 3 + 0.3
    numbersGroup_3.position.y = Math.sin(elapsedTime * 0.5) / 6 + 0.3

    // ballon 4
    numbersGroup_4.rotation.y = -Math.sin(elapsedTime * 0.5) / 3 + 0.3
    numbersGroup_4.position.y = Math.sin(elapsedTime * 0.5) / 6 + 0.3

    // move scene on mouse move
    if(window.innerWidth > 2560) {
        targetX = cameraX * 0.002
    } else {
        targetX = cameraX * 0.004
    }

    if(!isMobile) {
        camera.position.x += 0.05 * (targetX - camera.position.x) / 2
        camera.position.y += 0.05 * (targetY - camera.position.y) / 2
        camera.lookAt(scene.position)
    }


    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)
    // composer.render();

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

// Cart rotation effect 

if(!isMobile) {

const tiltEffectSettings = {
    max: 30, // max tilt rotation (degrees (deg))
    perspective: 2000, // transform perspective, the lower the more extreme the tilt gets (pixels (px))
    scale: 1, // transform scale - 2 = 200%, 1.5 = 150%, etc..
    speed: 10000, // speed (transition-duration) of the enter/exit transition (milliseconds (ms))
    easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)" // easing (transition-timing-function) of the enter/exit transition
  };
  
  const cards = document.querySelectorAll(".card");
  const footer = document.querySelector(".footer");

  
  cards.forEach(card => {
    card.addEventListener("mousemove", cardMouseMove);
    card.addEventListener("mouseleave", cardMouseLeave);
  });
  
  function cardMouseMove(event) {
    const card = event.currentTarget;
    const cardWidth = card.offsetWidth;
    const cardHeight = card.offsetHeight;
    const centerX = card.offsetLeft + cardWidth/2;
    const centerY = card.offsetTop + cardHeight/2;
    const mouseX = event.clientX - centerX;
    const mouseY = event.clientY - centerY;
    const rotateXUncapped = (+1)*tiltEffectSettings.max*mouseY/(cardHeight/2);
    const rotateYUncapped = (-1)*tiltEffectSettings.max*mouseX/(cardWidth/2);
    const rotateX = rotateXUncapped < -tiltEffectSettings.max ? -tiltEffectSettings.max : 
                    (rotateXUncapped > tiltEffectSettings.max ? tiltEffectSettings.max : rotateXUncapped);
    const rotateY = rotateYUncapped < -tiltEffectSettings.max ? -tiltEffectSettings.max : 
                    (rotateYUncapped > tiltEffectSettings.max ? tiltEffectSettings.max : rotateYUncapped);
  
    card.style.transform = `perspective(${tiltEffectSettings.perspective}px) rotateY(${rotateY}deg) 
                            scale3d(${tiltEffectSettings.scale}, ${tiltEffectSettings.scale}, ${tiltEffectSettings.scale})`;

    footer.style.transform = `translateX(${-mouseX/16}px)`;
  }
  
  function cardMouseLeave(event) {
    event.currentTarget.style.transform = `perspective(${tiltEffectSettings.perspective}px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)`;
  }
}